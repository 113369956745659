@if (showFooter) {
  <footer>
    <!-- Mobile View Items -->
    <div class="section section__mobile section__download-app">
      <div class="container">
        <a class="text-black-50 fs-8 text-decoration-none" href="https://dl.purplle.com/mCYg/YpYn34EPzr">
          <div class="d-flex gap-2 justify-content-center align-items-center">
            <img [src]="imageConstants.googlePlayIcon" loading="lazy" alt="Playstore Icon" width="18" height="22">
            <img [src]="imageConstants.appleIcon" loading="lazy" alt="Apple Icon" width="18" height="22">
          </div>
          <p class="mt-2 mb-0 text-center">Download Purplle App</p>
        </a>
      </div>
    </div>
    @if (breadCrumb) {
      <div class="section section__mobile section__breadcrumb">
        <div class="container">
          <pds-breadcrumb class="justify-content-center">
            @for (item of breadCrumb | slice: 0 : (breadCrumb.length - 1); track item) {
              <pds-breadcrumb-item>{{ item?.name
                | titlecase
              }}</pds-breadcrumb-item>
            }
            <pds-breadcrumb-item [active]="true">{{ breadCrumb[breadCrumb.length-1]?.name | titlecase
            }}</pds-breadcrumb-item>
          </pds-breadcrumb>
        </div>
      </div>
    }
    <div class="section section__mobile section__more-about-shopping">
      @if (!isSSR) {
        <pds-accordion [flush]="true">
          <pds-accordion-item #item0="pdsAccordionItem" [visible]="false">
            <ng-template pdsTemplateId="accordionHeaderTemplate">
              <button class="border-top border-bottom lh-sm" (click)="item0.toggleItem()"
                [collapsed]="!item0.visible" pdsAccordionButton>
                More about online shopping at purplle.com
              </button>
            </ng-template>
            <ng-template pdsTemplateId="accordionBodyTemplate">
              <div class="accordion-body p-0">
                <div class="section">
                  @if (metaData?.content_title || metaData?.content_description) {
                    <div class="brb1s bc-e8 pd15 pdb13"
                      >
                      @if (metaData?.content_title) {
                        <p class="mr0 fwb f14 tx-med mrb8">{{
                        metaData?.content_title }}</p>
                      }
                      @if (metaData?.content_description) {
                        <p class="mr0 f12 tx-med">{{
                        metaData?.content_description }}</p>
                      }
                    </div>
                  }
                </div>
                @if (metaLinks && metaLinks.length > 0) {
                  <div class="section section__browse">
                    <h6 class="mx-3 mb-0">BROWSE</h6>
                  </div>
                }
                @for (metaLink of metaLinks; track metaLink; let i = $index) {
                  <div class="section__accrodion">
                    <pds-accordion [flush]="true">
                      <pds-accordion-item #item0="pdsAccordionItem" [visible]="false">
                        <ng-template pdsTemplateId="accordionHeaderTemplate">
                          <button class="border-top border-bottom lh-sm" (click)="item0.toggleItem()"
                            [collapsed]="!item0.visible" pdsAccordionButton>
                            {{ metaLink?.section_heading }}
                          </button>
                        </ng-template>
                        <ng-template pdsTemplateId="accordionBodyTemplate">
                          <div class="accordion-body p-0">
                            <ul pdsListGroup [flush]="true">
                              @for (value of metaLink?.items; track value) {
                                <a
                                  href="{{ value.web_target_url }}"
                                  (click)="commonService.handleRoutesEventManager($event, value.web_target_url)"
                                class="border-0" pdsListGroupItem>{{ value.name }}</a>
                              }
                            </ul>
                          </div>
                        </ng-template>
                      </pds-accordion-item>
                    </pds-accordion>
                  </div>
                }
                @if (bestSellers) {
                  <div class="section section__price-list">
                    <h6 class="mx-3 mb-3">{{ bestSellers.title }}</h6>
                    <ul pdsListGroup [flush]="true">
                      <li class="d-flex justify-content-between align-items-center gap-3"
                        pdsListGroupItem>
                        <span class="text-black">{{ bestSellers.sub_title }}</span>
                        <span class="text-black">Price</span>
                      </li>
                      @for (seller of bestSellers.items; track seller; let i = $index) {
                        <li class="d-flex justify-content-between align-items-center gap-3"
                          pdsListGroupItem>
                          <a href="/product/{{ seller?.slug }}" target="_blank">{{ i + 1 }}. {{ seller?.name
                          }}</a>
                          <span>₹{{ seller?.price }}</span>
                        </li>
                      }
                    </ul>
                  </div>
                }
                <div class="section section__connect">
                  <div class="container">
                    <h6 class="mb-2 fw-bold text-dark">Connect</h6>
                    <div class="social-links d-flex align-items-center gap-2 text-center">
                      @for (link of socialLinks; track link) {
                        <a [href]="link.path" target="_blank"
                          [title]="link.name" [ngStyle]="{ 'background-color' : link.backgroundColor }">
                          <i class="{{ link.iconName }}"></i>
                        </a>
                      }
                    </div>
                  </div>
                </div>
                <div class="section section__useful-links">
                  <div class="container">
                    <h6 class="mb-2 d-block fw-bold text-dark">Useful Links</h6>
                    <div class="userful-links">
                      @for (link of usefulLinks; track link; let ind = $index) {
                        <a [title]="link.name"
                          [href]="link.path">{{
                          link.name }}
                          @if (ind != usefulLinks?.length - 1) {
                            <span> / </span>
                          }
                        </a>
                      }
                    </div>
                  </div>
                </div>
                @if (footerLinks?.length) {
                  <div class="section section__footer-links">
                    <div class="container">
                      @for (link of footerLinks; track link) {
                        <a class="mb-1 d-block fw-bold text-dark"
                          href="{{link?.web_target_url || link?.url}}"
                        (click)="commonService.handleRoutesEventManager($event, link?.web_target_url || link?.url)">{{link.title}}</a>
                        <div class="footer-links">
                          @for (item of link?.items; track item; let ind = $index) {
                            <a [title]="item?.name"
                              [href]="item?.web_target_url || item?.url" id="intralinkings-{{ind}}"
                              target="_blank"
                              (click)="commonService.handleRoutesEventManager($event, item?.web_target_url || item?.url)">
                              {{ item.name }}
                              @if (ind != link?.items?.length - 1) {
                                <span> / </span>
                              }
                            </a>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
                @if (listType) {
                  <div class="section section__mobile section__links">
                    @if (metaData?.isCorePages && metaData?.isCorePages == 1) {
                      <h1 [innerHTML]="metaData?.seo_description"></h1>
                    }
                    <div class="content p-3">
                      @if (metaData?.isListing && metaData?.isListing == 1) {
                        @if (metaData?.footer_title) {
                          <h1 class="fw-semibold mx-lg-0 mb-3">
                          {{metaData?.footer_title}}</h1>
                        }
                        <div [innerHTML]="metaData?.seo_description" class="inner-content"></div>
                      }
                      @if (!(metaData?.isCorePages) && !(metaData?.isListing)) {
                        @if (metaData?.footer_title) {
                          <h1 class="fw-semibold mx-lg-0 mb-3">
                          {{metaData?.footer_title}}</h1>
                        }
                      }
                      @if (!(metaData?.isCorePages) && !(metaData?.isListing) && metaData?.seo_description) {
                        <div [innerHTML]="metaData?.seo_description" class="inner-content"></div>
                      }
                    </div>
                  </div>
                }
              </div>
            </ng-template>
          </pds-accordion-item>
        </pds-accordion>
      }
    </div>
    <!-- Dexktop View Items -->
    <div class="section section__desktop section__payments">
      <div class="container">
        <div class="row">
          @for (section of sections; track section) {
            <div class="col-12 col-md-4 col-lg-4">
              <div class="px-3 py-4 py-lg-5 text-center">
                <a class="section-link text-decoration-none" [href]="section.path">
                  <img class="mw-100 h-auto" [src]="section.imageUrl" [alt]="section.title" width="120"
                    height="140" loading="lazy">
                  <h5 class="mt-4 fs-bold">{{ section.title }}</h5>
                  <p class="mb-0">{{ section.desc }}</p>
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="section section__desktop section__shop">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="px-3 py-4 py-lg-5 text-center">
              <h5 class="mt-0 mb-3 mb-lg-4 fs-bold">Spotlight</h5>
              <video width="400" height="300" [poster]="imageConstants.foundationFinderIcon" controls
                preload="none">
                <source [src]="imageConstants.purplleVideo" type="video/mp4">
              </video>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="px-3 py-4 py-lg-5 text-center">
              <h5 class="mt-0 mb-3 mb-lg-4 fs-bold">Shop on the go</h5>
              <a [href]="appDownloadLink" target="_blank">
                <img loading="lazy" [src]="imageConstants.appDownloadBanner" alt="Download App">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section__desktop section__links">
      <div class="container">
        <div class="card-box">
          @if (listType) {
            <div class="row">
              @if (metaData?.seo_description) {
                <div class="col-12" [ngClass]="{'col-md-8': bestSellers}">
                  @if (metaData?.isCorePages && metaData?.isCorePages == 1) {
                    <h1 [innerHTML]="metaData?.seo_description"></h1>
                  }
                  <div class="content p-3">
                    @if (metaData?.isListing && metaData?.isListing == 1) {
                      @if (metaData?.footer_title) {
                        <h1 class="mx-3 mx-lg-0 mb-3">
                        {{metaData?.footer_title}}</h1>
                      }
                      <div [innerHTML]="metaData?.seo_description" class="inner-content"></div>
                    }
                    @if (!(metaData?.isCorePages) && !(metaData?.isListing)) {
                      @if (metaData?.footer_title) {
                        <h1 class="mx-3 mx-lg-0 mb-3">
                        {{metaData?.footer_title}}</h1>
                      }
                    }
                    @if (!(metaData?.isCorePages) && !(metaData?.isListing) && metaData?.seo_description) {
                      <div [innerHTML]="metaData?.seo_description" class="inner-content"></div>
                    }
                  </div>
                </div>
              }
              @if (bestSellers) {
                <div class="col-12 best__seller_desktop"
                  [ngClass]="{'col-md-4': bestSellers && metaData?.seo_description}">
                  <h5 class="mx-3 mb-3">{{bestSellers?.title}}</h5>
                  <ul pdsListGroup [flush]="true">
                    <a class="d-flex justify-content-between align-items-center gap-3" pdsListGroupItem>
                      <span class="text-black">{{bestSellers?.sub_title}}</span>
                      <span class="text-black">Price</span>
                    </a>
                    @for (seller of bestSellers?.items; track seller; let i = $index) {
                      <a
                        class="d-flex justify-content-between align-items-center gap-3" pdsListGroupItem>
                        <a href="/product/{{seller?.slug}}" target="_blank">{{i+1}}.
                        {{seller?.name}}</a>
                        <span>₹{{seller?.price}}</span>
                      </a>
                    }
                  </ul>
                </div>
              }
            </div>
          }
          <div class="meta-links">
            @for (link of combinedLinks; track link) {
              <div class="links-box px-0 px-lg-4 pb-3 d-flex gap-1 gap-lg-3">
                <div class="head flex-shrink-0 flex-grow-0 py-1">
                  @if (link?.section_heading) {
                    <h6 class="heading mb-0 fs-8 fw-bold text-start text-uppercase">{{ link?.title }}</h6>
                  } @else {
                    <a class="heading mb-0 fs-8 fw-bold text-start text-uppercase" [href]="link?.web_target_url || link?.url" (click)="commonService.handleRoutesEventManager($event, link?.web_target_url || link?.url)">{{ link?.title }}</a>
                  }
                </div>
                <div class="content items flex-grow-1">
                  @for (item of link?.items; track item; let ind = $index) {
                    <a [title]="item?.name"
                      href="{{item?.web_target_url || item?.url}}" id="intralinkings-{{ind}}" target="_blank"
                      (click)="commonService.handleRoutesEventManager($event, item?.web_target_url || item?.url)">
                      {{item.name }}
                      @if (ind != link?.items?.length - 1) {
                        <span> / </span>
                      }
                    </a>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="section section__desktop section__grouped-links">
      <div class="container">
        <div class="d-flex gap-3 justify-content-around">
          @for (groupedLink of groupedLinks; track groupedLink) {
            <div>
              <h6 class="mb-3 fw-bold">{{ groupedLink.heading }}</h6>
              <ul pdsListGroup [flush]="true">
                @for (item of groupedLink.items; track item) {
                  <li pdsListGroupItem>
                    <a [href]="item?.path">{{ item.title }}</a>
                  </li>
                }
              </ul>
            </div>
          }
        </div>
        <hr>
        </div>
      </div>
      <div class="section section__desktop section__social-links">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex gap-4 align-items-center justify-content-center">
                <h6 class="m-0 fw-bold f-grey">Payment</h6>
                <img class="mw-100 h-auto" loading="lazy" [src]="imageConstants.paymentOptionsIcon" alt="Payment Options"
                  width="300" height="27">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex gap-4 align-items-center justify-content-center">
                <h6 class="m-0 fw-bold f-grey">Connect</h6>
                <div class="social-links d-flex gap-2">
                  @for (link of socialLinks; track link) {
                    <a [href]="link.path" target="_blank" [title]="link.name"
                      [ngStyle]="{ 'background-color' : link.backgroundColor }">
                      <i class="{{ link.iconName }}"></i>
                    </a>
                  }
                </div>
              </div>
            </div>
            <div class="col-12">
              <p class="mt-4 mb-0 fs-7 text-center">Copyright © 2020 Purplle. All
              Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  }
