import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
// Configs
// Services
import { NgClass, NgStyle, SlicePipe, TitleCasePipe } from '@angular/common';
import { AccordionModule } from '@purplle/pds/accordion';
import { BreadcrumbModule } from '@purplle/pds/breadcrumb';
import { ListGroupModule } from '@purplle/pds/list-group';
import { SharedModule } from '@purplle/pds/shared';
import { AppCommonService } from "@services/app-common.service";
import { Subscription } from "rxjs";
import { AppDownloadLinks, FooterSectionConstants } from "src/app/constants/application/application-constants";
import { ImageConstants } from "src/app/constants/application/image-constants";
import { GROUPED_LINKS, META_LINKS, SECTIONS, SOCIAL_LINKS, USEFUL_LINKS } from "../app-footer.config";
import { FooterService } from "../footer.service";

@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.scss'],
    standalone: true,
    imports: [
        BreadcrumbModule,
        AccordionModule,
        SharedModule,
        ListGroupModule,
        NgStyle,
        NgClass,
        SlicePipe,
        TitleCasePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {
  public readonly srcset = { icon: "50w, 80w, 100w, 120w, 150w" };
  public isDesktop: boolean = this.commonService.isDesktop;

  public sections: any[];
  public metaLinks: any[];
  public socialLinks: any[];
  public usefulLinks: any[];
  public groupedLinks: any[];

  listType: string;
  stickToBottom: boolean;
  combinedLinks: any[];
  metaData: any = [];
  bestSellers: any;
  footerLinks: any[];
  breadCrumb: any[];
  showFooter: boolean = true;
  showFooterGap: boolean = false;
  footerSubscriptions = new Subscription();
  public imageConstants = ImageConstants;
  public appDownloadLink = AppDownloadLinks.appDownloadLink;
  isSSR: boolean = typeof window === "undefined";

  @HostBinding('class')
  get hostClasses(): any {
    return {
      'footer-gap': this.showFooterGap
    };
  }

  constructor(public commonService: AppCommonService, private footerService: FooterService) { 
    this.footerSubscriptions.add(
      this.footerService.getFooterConfig().subscribe((conf: any)=>{
        this.setConfig(conf.config, conf.value);
      })
    );
    this.footerSubscriptions.add(
      this.footerService.getMetadataConfig().subscribe((meta: any) => {
        this.bindData(meta);
      })
    );
  }

  ngOnInit() {
    this.sections = SECTIONS.filter(section => section);
    this.socialLinks = SOCIAL_LINKS.filter(link => link);
    this.usefulLinks = USEFUL_LINKS.filter(link => link);
    this.groupedLinks = GROUPED_LINKS.filter(link => link);
  }

  ngOnDestroy() {
    this.footerSubscriptions?.unsubscribe();
  }

  bindData(metaData) {
    this.metaData = metaData;
    this.bestSellers = null;
    this.footerLinks = [];
    this.metaLinks = [];
    this.breadCrumb = [];
    this.bestSellers = this.metaData?.bestsellers;
    this.footerLinks = this.metaData?.footer_links_list || [];
    
    this.metaLinks = (this.metaData?.intralinks || []).map(link => ({
      ...link,
      title: link.title.toUpperCase()
    }));
  
    this.breadCrumb = this.metaData?.breadcrum;

    if(this.footerLinks?.length == 0 && this.metaLinks?.length == 0 && this.isDesktop) {
      this.metaLinks = META_LINKS;
    }
    this.modifyData();
      }

  modifyData() {
    this.footerLinks = this.footerLinks.map((obj) => {
      return { title: obj.name, web_target_url: obj.url, items: [...obj.values] };
    });
    if(this.isDesktop) {
      this.combinedLinks = this.listType ? [...this.metaLinks, ...this.footerLinks] : this.footerLinks.length > 0 ? this.footerLinks : this.metaLinks;
    }
  }

  setConfig(key: string, newVal: any) {
    if (key == FooterSectionConstants.showFooter) {
      this.showFooter = newVal;
      if(newVal == false) {
        !this.isSSR && document.documentElement.style.setProperty("--footer-gap", "0px");
      }
    } else if (key == FooterSectionConstants.listType) {
      this.listType = newVal;
    }  else if (key == FooterSectionConstants.stickToBottom) {
      this.stickToBottom = newVal;
    } else if (key == FooterSectionConstants.showFooterGap) {
      this.showFooterGap = newVal;
    }
  }
}